import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import {
	EcommerceProductType,
	EcommerceProduct,
} from '@zyro-inc/site-modules/types';

import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import {
	SiteBlocks,
	SitePage,
	SiteEcommerceDynamicProductPage,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import {
	getAddToCartEventPayload,
	googleTagManagerEvent,
} from '@zyro-inc/site-modules/utils/googleTagManager';

export const useListProduct = ({ blockId }: { blockId?: string }) => {
	const {
		openEcommerceModal,
		closeEcommerceModal,
		setProductPreviewData,
	} = useEcommerceModal();

	const {
		legacyProductPages,
		isShoppingCartOpen,
		shoppingCartItems,
		setShoppingCartOpen,
		setShoppingCartItems,
		setIsCheckoutLoading,
		setSelectedBookingProduct,
		dynamicPageTemplateData,
	} = useEcommerceGlobal({
		blockId,
	});

	const { initiateCheckout } = useEcommerce();

	const { hasGoogleAdsApp } = useSiteGlobal();

	const addProductToBag = async ({
		product,
		isInPreviewMode,
		isDynamicProductPageEnabled,
		isCartVisible,
		blocks,
	}: {
        product: EcommerceProduct,
        isInPreviewMode: boolean,
        isDynamicProductPageEnabled: boolean,
        isCartVisible: boolean,
        blocks: SiteBlocks
    }) => {
		if (isInPreviewMode) {
			openEcommerceModal('EcommerceMessageButtonDisabled');

			return;
		}

		const hasMandatoryCustomFields = product.custom_fields?.some((field) => field.is_required);

		if (product.options.length || hasMandatoryCustomFields) {
			const itemProductPage: SitePage | undefined = Object.values(legacyProductPages.value)
				.find((page: SitePage) => (page as SiteEcommerceDynamicProductPage).productId === product.id);
			const ecommerceBlocks = Object.keys(blocks).filter((key) => blocks[key].type === 'BlockEcommerceProduct');
			const pageProductBlockId = isDynamicProductPageEnabled
				? ecommerceBlocks.find((block) => dynamicPageTemplateData.value?.blocks?.includes(block))
				: ecommerceBlocks.find((block) => itemProductPage?.blocks?.includes(block));

			if (!pageProductBlockId) {
				if (!itemProductPage) {
					window.location.assign('/');

					return;
				}

				window.location.assign(`/${product.seo_settings.slug}`);

				return;
			}

			setProductPreviewData(blocks[pageProductBlockId], product.id);
			openEcommerceModal('EcommerceProductPreview');

			return;
		}

		const productForShoppingCart = [
			{
				...product,
				variants: [product.variants[0]],
			},
		];

		await closeEcommerceModal();

		if (product.type.value === EcommerceProductType.BOOKING) {
			setSelectedBookingProduct(product);
			openEcommerceModal('EcommerceBookingEventSelect');

			return;
		}

		if (product.type.value === EcommerceProductType.DIGITAL) {
			const isProductAlreadyInCart = shoppingCartItems.value.some((item) => item.id === product.id);

			if (isProductAlreadyInCart) {
				setShoppingCartOpen(true);

				return;
			}
		}

		if (isCartVisible) {
			setShoppingCartItems([
				...shoppingCartItems.value,
				...productForShoppingCart,
			]);

			if (hasGoogleAdsApp.value) {
				googleTagManagerEvent('add_to_cart', getAddToCartEventPayload(productForShoppingCart[0]));
			}

			if (isShoppingCartOpen.value) {
				return;
			}

			setShoppingCartOpen(true);
		} else {
			setIsCheckoutLoading(true);
			await initiateCheckout(productForShoppingCart).then(() => {
				setIsCheckoutLoading(false);
			});
		}
	};

	return {
		addProductToBag,
	};
};
